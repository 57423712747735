import {
  Session,
  SessionTime,
} from '../types';
import {
  initialSessionState,
  initialSessionTimeState,
} from '../constants';
import {
  AnyAction,
} from 'redux';

const sessionTimeReducer = (state : SessionTime = initialSessionTimeState, action : AnyAction) => {
  if (action.type === 'SET_TIME_ELAPSED') {
    return {
      timeElapsed: action.data,
    };
  }

  return {
    ...state,
  };
};
const sessionReducer = (state : Session = initialSessionState, action : AnyAction) => {
  const handleUpdateState = () => {
    const {
      states: itemStates,
    } = state;
    const {
      id,
    } = action.data;
    const existingItemStateIndex = itemStates.findIndex(({
      itemId,
    }) => itemId === id);
    const newItemState = action.data;
    if (existingItemStateIndex !== -1) {
      itemStates[existingItemStateIndex] = newItemState;
    } else {
      itemStates.push(newItemState);
    }
    return {
      ...state,
      states: [...itemStates],
    };
  };
  switch (action.type) {
    case 'SESSION_CREATE':
    case 'SESSION_RESTORE':
      return {
        ...state,
        loading: true,
      };
    case 'SESSION_CREATE_SUCCESS':
    case 'SESSION_RESTORE_SUCCESS':
      return {
        ...state,
        ...action.data,
        loading: false,
        launchSession: true,
      };
    case 'TOGGLE_SESSION_CREATE_FAILURE':
      return {
        loading: false,
        error: !state.error,
      };
    case 'RESET_SESSION_LAUNCH':
      return {
        ...state,
        launchSession: false,
      };
    case 'SESSION_UPDATE_STATE':
      return handleUpdateState();
    case 'SESSION_RESET':
      return {
        ...initialSessionState,
      };
    default:
      return state;
  }
};

export {
  sessionReducer,
  sessionTimeReducer,
};
