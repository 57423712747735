/* -----------------Globals--------------- */
import {
  useEffect,
  useState,
} from 'react';

import * as Sentry from '@sentry/nextjs';
/* -----------------Next js--------------- */
import Router, {
  useRouter,
} from 'next/router';

/* -----------------Analytics--------------- */
import {
  trackLogin,
  trackPageView,
  PageTypes,
} from '../src/analytics/tracking';

/* -----------------UI--------------- */
import {
  Box,
  CircularProgress,
} from '@mui/material';

/* -----------------Constants--------------- */
import {
  errorData,
} from '@utils/error';

/* -----------------Context--------------- */
import {
  useAuthContext,
} from '../src/context/AuthContext';

/* -----------------Utils--------------- */
import {
  store,
  Keys,
} from '../src/utils/auth';

/* -----------------Api--------------- */
import {
  handleExternalAuth,
} from '@features/auth/';

/* -----------------Child components--------------- */
import ErrorView from '@components/ErrorView';
import PageContainer from '@components/layout/PageContainer';

export default function Splash() {
  const {
    setUser,
    userId,
  } = useAuthContext();
  const [loading, setLoading] = useState(true);

  const {
    query: {
      token,
    },
    isReady,
  } = useRouter();

  useEffect(() => {
    trackPageView({
      pageType: PageTypes.splash,
    });
  }, []);
  useEffect(() => {
    if (userId) {
      Router.push('./dashboard');
    }
  }, [userId]);

  useEffect(() => {
    if (isReady) {
      if (typeof token === 'string') {
        setLoading(true);

        const input = {
          token: token.replace(/ /g, '+'),
          tenantId: 'MH',
          appId: 'appid',
        };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        handleExternalAuth(input).then((res : any) => {
          const {
            userId: resUserId,
            email,
            userName,
            firstName,
            lastName,
          } = res;
          const fullName = `${firstName} ${lastName}`;
          trackLogin(resUserId);
          store(Keys.userId, resUserId);
          store(Keys.userName, userName);
          store(Keys.userFullName, fullName);
          store(Keys.email, email);
          setUser();
          Router.push('./dashboard');
        }).catch((e) => {
          Sentry.captureException({
            serverError: e,
            token: JSON.stringify(token),
          });
          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    }
  }, [isReady, token, setUser]);

  if (loading) {
    return (
      <PageContainer>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="80vh"
        >

          <CircularProgress />
        </Box>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '50vh',
          py: 3,
        }}
      >
        <ErrorView
          {...errorData.unauth}
        />
      </Box>
    </PageContainer>
  );
}
