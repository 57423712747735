/* -----------------UI--------------- */
import { CircularProgress, Box } from '@mui/material';

const Loader = () => (
  <Box
    display="flex"
    height="100vh"
    justifyContent="center"
    alignItems="center"
  >
    <CircularProgress />
  </Box>
);

export default Loader;
