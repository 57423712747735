// @flow
/* -----------------Third parties--------------- */
import awsExports from '../aws-exports.json';
// eslint-disable-next-line import/extensions

/* -----------------Third parties--------------- */
import Auth from '@aws-amplify/auth';

const UserPoolClients = JSON.parse(awsExports.UserPoolClients);

const redirectSignIn = UserPoolClients.webClientCallbackURLs;
const redirectSignOut = UserPoolClients.webClientLogoutURLs;

const graphqlHeader = async () => {
  try {
    const token = await (await Auth.currentSession()).getIdToken().getJwtToken();
    return { Authorization: token };
  } catch (e) {
    return {};
  }
};

const setUpConfig = () => ({
  Auth: {
    userPoolId: awsExports.UserPoolId,
    userPoolWebClientId: awsExports.UserPoolClientIdWeb,
    identityPoolId: awsExports.IdentityPoolId,
    region: awsExports.Region,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  },
  aws_appsync_graphqlEndpoint: awsExports['API-Endpoint'],
  aws_appsync_region: awsExports.Region,
  aws_appsync_authenticationType: 'API_KEY',
  aws_appsync_apiKey: awsExports['API-Key'],
  graphql_headers: graphqlHeader,
  oauth: {
    domain: awsExports.UserPoolDomain,
    scope: UserPoolClients.webClientScope,
    redirectSignIn,
    redirectSignOut,
    responseType: 'token',

  },
});

export default setUpConfig;
