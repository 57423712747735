/* -----------------UI--------------- */
import {
  Stack,
  Typography,
  Fab,
} from '@mui/material';
import Image from 'next/image';

interface Props {
    title : string,
    subtitle : string,
    imagePath : string,
    cta? : string,
    target? : string,
    href? : string
}
const ErrorView = (props : Props) => {
  const {
    title,
    subtitle,
    imagePath,
    cta,
    target = '_self',
    href,
  } = props;

  return (
    <Stack
      direction="column"
      spacing={3}
      alignItems="center"
    >
      <Image
        src={imagePath}
        width={300}
        height={200}
        alt="401"
      />
      <Typography
        variant="h4"
        sx={{
          fontWeight: 700,
        }}
      >
        {title}
      </Typography>
      <Typography>{subtitle}</Typography>
      {cta && href ? (
        <Fab
          variant="extended"
          color="primary"
          href={href}
          target={target}
        >
          {cta}
        </Fab>
      ) : null}
    </Stack>
  );
};

export default ErrorView;
