// @flow

/* -----------------Globals--------------- */
import {
  commitMutation,
  graphql,
} from 'react-relay';
import {
  ExternalAuthResponse,
  AuthResponse,
  ExternalAuthInput,
} from '../../types';
import environment from '@relay/env';

const mutation = graphql`
      mutation HandleExternalAuthMutation($authInput: ExternalAuthInput!) {
        handleExternalAuth(authInput: $authInput) {
          status
          message
          data{
              firstName
              lastName
              userId
              userName
              email
          }

        }
      }
    `;

const handleExternalAuth = async (
  authInput : ExternalAuthInput,
): Promise<AuthResponse | unknown> => {
  if (!environment.isRehydrated()) {
    await environment.hydrate();
  }
  const promise = new Promise((resolve, reject) => {
    const variables = {
      authInput,
    };
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (response) => {
        const {
          handleExternalAuth: externalAuthResponse,
          handleExternalAuth: {
            status,
            data,
          },
        } = response as ExternalAuthResponse;

        if (data && status === 200) { resolve(data); }
        reject(externalAuthResponse);
      },
      onError: (error) => {
        reject(error);
      },
    });
  });
  return promise;
};

export {
  // eslint-disable-next-line import/prefer-default-export
  handleExternalAuth,
};
