import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import {
  Progress,
} from './types/theme';

declare module '@mui/material/styles' {
  interface Palette{
    progress : Progress
  }
  interface PaletteOptions{
    progress : Progress
  }
}
// Define primary and secondary theme colors
const primary = {
  main: '#2C6C46',
};

const secondary = {
  main: '#2E83BC',
  light: '#80AEE1',
};

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary,
    secondary,
    error: {
      main: red.A400,
    },
    background: {
      default: '#e5e5e5',
    },
    progress: {
      high: '#66bb68',
      medium: '#dda53a',
      low: '#d66565',
      none: '#ddd',
      noneBackground: '#888888', // old: '#888888'
      lowBackground: 'rgba(255,105,97, 0.3)', // old: '#ffb4b0'
      highBackground: 'rgba(0, 168, 106, 0.3)', // old: '#90d3b5'
    },
  },

  typography: {
    fontFamily: [
      'Open Sans',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  components: {
    MuiListSubheader: {
      styleOverrides: {
        root: {
          background: 'transparent',
          letterSpacing: 1.25,
          textTransform: 'uppercase',
          fontSize: 12,
          fontWeight: 'bold',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h6: {
          fontWeight: 600,
          fontSize: 20,
        },
        overline: {
          fontSize: 10,
          fontWeight: 600,
          letterSpacing: 1.0,
          textTransform: 'uppercase',
          color: 'rgba(0,0,0,0.60)',
          lineHeight: 'inherit',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          letterSpacing: 1.25,
          fontWeight: 'bold',
          textTransform: 'none',
        },
        outlinedSecondary: {
          borderWidth: 2,
          borderColor: secondary.main,
          '&:hover': {
            borderWidth: 2,
          },
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          letterSpacing: 1.25,
          fontWeight: 'bold',
          width: 200,
          maxWidth: '100%',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderWidth: 2,
          letterSpacing: 0.5,
          fontWeight: 600,
          minWidth: 64,
        },
      },
    },
  },
});

export default theme;
