/* -----------------Globals--------------- */
import React from 'react';

/* -----------------Third parties--------------- */
import { I18n } from '@aws-amplify/core';

/* -----------------UI--------------- */
import {
  Typography,
  Fab,
  Box,
  useTheme,
} from '@mui/material';

/* -----------------Icons--------------- */
import {
  EmailOutline,
} from 'mdi-material-ui';

/* -----------------Props--------------- */
type Props = {
  title: string,
  disableSocialLogin: boolean,
  showEmailInput: boolean | undefined,
  variant: string,
  handleShowEmailInput: (show? : boolean | undefined) => void,
  subtitle? : string
}

/* -----------------Component--------------- */
const AuthHeader = (props: Props) => {
  const {
    title,
    variant,
    disableSocialLogin,
    showEmailInput,
    handleShowEmailInput,
    subtitle,
  } = props;

  const theme = useTheme();

  const emailLogin = {
    type: 'email',
    action: handleShowEmailInput,
    icon: <EmailOutline
      sx={{
        position: 'absolute',
        left: 16,
        color: theme.palette.text.primary,
      }}
    />,
  };

  interface FabType {
    type : string,
    action : () => void,
    icon : React.ReactNode
  }
  const FabAuth = (p : FabType) => {
    const { type, action, icon } = p;
    const getCTA = () => {
      if (type === 'email') {
        switch (variant) {
          case 'signup':
            return 'SIGN UP';
          case 'login':
            return 'LOGIN';
          default:
            return 'CONTINUE';
        }
      } else {
        return 'CONTINUE';
      }
    };
    const CTA = getCTA();
    return (
      <Fab
        variant="extended"
        sx={{
          borderRadius: 24,
          width: '100%',
          '&:hover': {
            filter: 'brightness(1.2)',
            transition: '0.2s all ease-out',
          },
          backgroundColor: 'transparent',
          borderColor: theme.palette.text.secondary,
          color: theme.palette.text.primary,
          borderWidth: 2,
          borderStyle: 'solid',
          boxShadow: 'none',
        }}
        onClick={action}
        disabled={disableSocialLogin}
      >
        {icon}
        {`${CTA} with ${type}`}
      </Fab>
    );
  };

  return (
    <>
      <Box mb={3}>
        <Typography component="h1" variant="h5" align="center">
          {I18n.get(title)}
        </Typography>
        {(subtitle != null)
          ? (
            <Typography variant="subtitle1" align="center" color="textSecondary">
              <em>{I18n.get(subtitle)}</em>
            </Typography>
          ) : null }
      </Box>
      {/* {socialLogins.map((login) => (
        <Box my={2}>
          <FabAuth {...login} />
        </Box>
      ))} */}
      {/* <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        className={classes.divider}
      >
        <Divider classes={{ root: classes.dividerLine }} />
        <Typography variant="overline" className={classes.dividerText}>{I18n.get('OR')}</Typography>
        <Divider classes={{ root: classes.dividerLine }} />
      </Box> */}
      {!showEmailInput
        ? (
          <Box my={3}>
            <FabAuth {...emailLogin} />
          </Box>
        )
        : null}
    </>
  );
};

export default AuthHeader;
