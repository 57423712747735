/* -----------------Globals--------------- */
import React, {
  useCallback,
  useState,
} from 'react';
import {
  Keys,
  retrieve,
} from '../utils/auth';
import Router from 'next/router';
import {
  whitelistedBetaUserIds,
} from '../constants';
import isEmpty from 'lodash/isEmpty';

  /* -----------------Props--------------- */
  type AuthContextValue = {
    isAuthenticated: boolean,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    user: any,
    err: boolean,
    loading: boolean,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setUser : any,
    userId : string | null,
    userName : string | null,
    userFullName : string |null
  }

/* -----------------Constants--------------- */
const initialContextValue: AuthContextValue = {
  isAuthenticated: false,
  user: null,
  setUser: undefined,
  err: false,
  loading: false,
  userId: null,
  userName: null,
  userFullName: null,
};

/* -----------------Context--------------- */
const Context = React.createContext<AuthContextValue>(
  initialContextValue,
);

const AuthProvider = ({ children } : {children : React.ReactNode}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [user] = useState<any>(null);
  const [userId, setUserId] = useState<string | null>(null);
  const [userName, setUserName] = useState<string | null>(null);
  const [userFullName, setUserFullName] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  React.useEffect(() => {
    // TODO:Remove after beta testing
    if (userId && Router.asPath !== '/404') {
      const notABetaUser = !whitelistedBetaUserIds.includes(userId);
      if (notABetaUser) {
        // Router.push('/404');
      }
    }
  }, [userId]);

  const setUserDetails = useCallback(() => {
    setLoading(true);
    const storedUserId = retrieve(Keys.userId);
    const storedUserName = retrieve(Keys.userName);
    const storeFullName = retrieve(Keys.userFullName);
    setUserId(storedUserId);
    setUserName(storedUserName);
    setUserFullName(storeFullName);
    setLoading(false);
  }, []);
  React.useEffect(() => {
    setUserDetails();
  }, [setUserDetails]);

  const handleSetUser = useCallback(() => {
    setUserDetails();
  }, [setUserDetails]);
  const contextValue = {
    isAuthenticated: !isEmpty(userId),
    user,
    err: false,
    loading,
    setUser: handleSetUser,
    userId,
    userName,
    userFullName,
  };
  return (
    <Context.Provider value={contextValue}>
      {children}
    </Context.Provider>
  );
};

const useAuthContext = () => {
  const context = React.useContext(Context);
  return context;
};
export {
  Context as AuthContext,
  AuthProvider,
  useAuthContext,
};
