import {
  Tenant,
} from '../types/tenant';

const tenantConfig = {
  [Tenant.mh]: {
    appLinkAndroid: 'https://play.google.com/store/apps/details?id=com.memorang.psi.insurance.testprep&hl=en_IN&gl=US',
    appLinkIOS: 'https://apps.apple.com/us/app/memorang-flashcards-quizzes/id916615301',
    zendeskKey: process.env.ZENDESK_CLIENT_KEY,
    mixPanelKey: process.env.MIXPANEL_SECRET_KEY,
    appName: 'NAPLEX® Practice Test Central',
    logo: '/tenant/mh/logo.png',
    companyName: 'MCGRAW-HILL',
    numMaxQuestionsPerTest: 225,
  },
};
const appTitle = 'NAPLEX® Practice Test Central';

export {
  tenantConfig,
  appTitle,
};
