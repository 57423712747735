/* eslint-disable @next/next/no-img-element */
/* -----------------Globals--------------- */
import React from 'react';
import {
  useTenantContext,
} from '../../../context/TenantContext';

/* -----------------MUI--------------- */
import {
  Grid,
  Stack,
  Typography,
} from '@mui/material';

const PartnerLogo = () => {
  const {
    logo,
    companyName,
  } = useTenantContext();
  return (
    <Grid
      item
    >
      <Stack
        direction="column"
        alignItems="center"
        gap={2}
      >
        <img
          width={285}
          height={50}
          style={{
            backgroundColor: 'red',
          }}
          src={logo}
          alt="footer logo"
        />
        <Typography
          variant="overline"
        >
          {`Copyright @ ${new Date().getUTCFullYear()} ${companyName}`}
        </Typography>
      </Stack>
    </Grid>
  );
};

export default PartnerLogo;
