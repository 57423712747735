/* -----------------Globals--------------- */
import React from 'react';

import {
  useRouter,
} from 'next/router';

/* -----------------MUI--------------- */
import {
  Container,
  Grid,
  Paper,
} from '@mui/material';

// /* -----------------Constants--------------- */
// import {
//   footerLinks,
// } from './constants';

/* -----------------Context--------------- */
import {
  usePageContext,
} from '../../../context/PageContext';

/* -----------------Child components--------------- */
import PartnerLogo from './PartnerLogo';
// import Links from './Links';
import PoweredBy from './PoweredBy';

const Footer = () => {
  const {
    pageType,
  } = usePageContext();

  const {
    isReady,
  } = useRouter();
  const hideFooter = pageType === 'Session' || pageType === 'Review';

  if (hideFooter || !isReady) {
    return null;
  }
  return (
    <footer>
      <Paper square>
        <Container maxWidth="lg">
          <Grid
            container
            direction="row"
            spacing={2}
            sx={{
              py: 1,
              pb: 6,
              flexDirection: {
                xs: 'column',
                sm: 'column',
                md: 'row',
              },
              justifyContent: {
                xs: 'center',
                sm: 'center',
                md: 'space-between',
              },
              alignItems: {
                xs: 'center',
                sm: 'center',
                md: 'flex-start',
              },
            }}
          >
            <Grid item>
              <PartnerLogo />
            </Grid>
            {/* {footerLinks.map(({
            subheader,
            links,
          }) => (
            <Links
              subheader={subheader}
              key={subheader}
              links={links}
            />
          ))} */}
            <Grid item>
              <PoweredBy />
            </Grid>
          </Grid>
        </Container>
      </Paper>
    </footer>
  );
};
export default Footer;
