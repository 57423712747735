/* -----------------Globals--------------- */
import React from 'react';
import {
  TenantStrings,
  Tenant,
} from '../types/tenant';
import {
  tenantConfig,
} from '../constants/tenant';

type ContextValue = {
  tenant : TenantStrings,
  appLinkAndroid : string,
  appLinkIOS : string,
  zendeskKey : string | undefined,
  appName : string,
  logo : string,
  companyName : string,
  numMaxQuestionsPerTest: number
}
const initialValue = {
  tenant: Tenant.mh,
  ...tenantConfig[Tenant.mh],
};
const Context = React.createContext<ContextValue>(initialValue);

const TenantProvider = ({ tenant, children } :
  {tenant : TenantStrings, children : React.ReactNode}) => {
  const value = {
    tenant,
    ...tenantConfig[tenant],
  };
  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  );
};

const useTenantContext = () => {
  const context = React.useContext(Context);
  return context;
};

export {
  useTenantContext,
  Context as TenantContext,
  TenantProvider,
};
