const listItemType = {
  'previous-tests': 'Previous Tests',
  mastery: 'Mastery',
};

// Global content id to be used for session/dashboard/report
// Make sure to replace this if content id is changed/new content imported
// to test easily, use this
// const contentId = '0f07d737-8a4a-4ebd-a944-bf9f46770d94';
// production one
const contentId = '7827ce45-f082-4472-b27f-70629dd62263';

const whitelistedBetaUserIds = ['ff9da967-0b5d-4353-bcb8-a14085169c64'];

// ranges for scores
const RANGE_FAIL = [0, 68];
const RANGE_BORDERLINE = [68, 82];
const RANGE_PASS = [82, 101];

// in seconds
const timePerQuestion = 96;
const Locales = {
  'en-us': 'en-us',
};
const MIN_AVAILABLE_ITEMS_SLIDER = 10;
export {
  whitelistedBetaUserIds,
  contentId,
  listItemType,
  RANGE_FAIL,
  RANGE_BORDERLINE,
  RANGE_PASS,
  timePerQuestion,
  Locales,
  MIN_AVAILABLE_ITEMS_SLIDER,
};
