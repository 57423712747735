import React from 'react';

/* -----------------UI--------------- */
import {
  Breadcrumbs,
  Link,
  Typography,
} from '@mui/material/';

import {
  ChevronRight,
} from 'mdi-material-ui';

interface BreadcrumbProp {
  label : string,
  href? : string,
 }
interface Props {
  breadcrumbs : BreadcrumbProp[]
}
const CustomBreadcrumb = (props : Props) => {
  const {
    breadcrumbs,
  } = props;

  const WithLink = ({ children, href } : {
    children : React.ReactElement,
    href : string
  }) => (
    <Link
      href={href}
      color="secondary.main"
      sx={{
        textDecoration: 'none',
      }}
    >
      {children}

    </Link>
  );

  const BreadCrumbText = ({
    label,
  } : {
    label : string,
  }) => (
    <Typography variant="h6">{label}</Typography>
  );
  return (
    <Breadcrumbs separator={<ChevronRight />}>
      {breadcrumbs.map(({
        label,
        href,
      }) => (href
        ? (
          <WithLink
            href={href}
            key={label}
          >
            <BreadCrumbText
              label={label}
            />
          </WithLink>
        )
        : (
          <BreadCrumbText
            label={label}
          />
        )))}
    </Breadcrumbs>
  );
};
export type{
  BreadcrumbProp,
};
export default CustomBreadcrumb;
