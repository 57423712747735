/* eslint-disable @typescript-eslint/no-explicit-any */
/* -----------------Third parties--------------- */
import API, { graphqlOperation } from '@aws-amplify/api';
import { Network } from 'relay-runtime';
import EnvironmentIDB from 'react-relay-offline/lib/runtime/EnvironmentIDB';
import { Store, Environment, RecordSource } from 'react-relay-offline';
import {
  RelayLazyLogger,
} from '@adeira/relay-runtime';

async function fQuery(operation: { text: any; }, variables: object | undefined) {
  return API.graphql(graphqlOperation(operation.text, variables));
}

const network = Network.create(fQuery as any);

let relayEnvironment: Environment;
function createLocalStorageEnvironment() {
  const recordSource = new RecordSource();
  const store = new Store(recordSource);
  const environment = new Environment({
    network,
    store,
  });
  return environment;
}

function createIndexedDB(records: any) {
  const recordSourceOptions = {
    initialState: records,
    mergeState: (restoredState: { [x: string]: any; }, initialState = {}) => {
      if (!restoredState) {
        return initialState;
      }
      if (restoredState && restoredState['0']) {
        // test
        const newStat = {
          ...restoredState,
          // $FlowFixMe
          0: {
            ...restoredState['0'],
            text: 'changed',
          },
        };
        return newStat;
      }
      return restoredState;
    },
  };
  const idbOptions = undefined;
  const environment = EnvironmentIDB.create(
    {
      network,
      log: RelayLazyLogger,
    },
    idbOptions,
    recordSourceOptions as any,
  );
  return environment;
}
export const manualExecution = false;
function createWebEnvironment(options: any = {}) {
  const { records = {} } = options;

  const createEnvironment = (indexed: boolean) => {
    const environment = indexed
      ? createIndexedDB(records)
      : createLocalStorageEnvironment();
    return environment;
  };

  if (typeof window === 'undefined') {
    return createEnvironment(false);
  }

  // reuse Relay environment on client-side
  if (!relayEnvironment) {
    relayEnvironment = createEnvironment(true);
  }

  return relayEnvironment;
}
export default createWebEnvironment;
