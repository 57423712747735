/* eslint-disable @next/next/no-img-element */
/* -----------------Globals--------------- */
import React from 'react';

/* -----------------Context--------------- */
import {
  useAuthContext,
} from '../../context/AuthContext';

/* -----------------UI--------------- */
import {
  AppBar, Container, Stack, Typography,
} from '@mui/material';

interface Props {
  children : React.ReactElement | null,
}
const Header = (props: Props) => {
  const {
    children,
  } = props;

  const {
    isAuthenticated,
    userFullName,
  } = useAuthContext();

  return (
    <AppBar color="default" position="static">
      <Container maxWidth="lg">
        <Stack spacing={2} py={2}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <img
              alt="logo"
              src="/logo.svg"
              height={53}
            />
            {isAuthenticated && (
              <Typography
                variant="body1"
                sx={{
                  textTransform: 'capitalize',
                }}
                color="secondary.main"
              >
                {userFullName}
              </Typography>
            )}
          </Stack>
          {children}
        </Stack>
      </Container>
    </AppBar>
  );
};

export default Header;
