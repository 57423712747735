interface TypeStatItem {
    title : string,
    subtitle? : string,
    icon? : React.ReactNode,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    renderCustomIcon? : () => void,
    stat? : string | number,
    button? : false | undefined,
    statColor? : string,
    statBackground? : string,
    id? : string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    childTags? : any
  }

  interface TypeSessionItem extends TypeStatItem {
    path : string
  }
  interface CardProps {
    title : string,
    subheader : string,
    items : TypeStatItem[],
    fullWidth? : boolean,
    variant : string
  }

  enum PageTypes {
    'dashboard' = 'Dashboard',
    'report' = 'Report',
    'session' = 'Session',
    'splash' = 'Splash',
    'listDashboard' = 'List_(Dashboard)',
    'listReport' = 'List_(Report)',
    'review' = 'Review',
    'customSessionConfig' = 'Custom_Session_Config'
}
export {
  PageTypes,
};
export type {
  CardProps,
  TypeStatItem,
  TypeSessionItem,
};
