/* -----------------Globals--------------- */
import React, { useState } from 'react';

/* -----------------Third parties--------------- */
import {
  I18n,
} from '@aws-amplify/core';
import {
  Formik,
  Form,
  Field,
} from 'formik';
import {
  string,
  object,
} from 'yup';
import { TextField } from 'formik-mui';

/* -----------------UI--------------- */
import {
  Button,
  Typography,
  CircularProgress,
  useTheme,
} from '@mui/material';

/* -----------------Context--------------- */
import Router, { useRouter } from 'next/router';

/* -----------------Constants--------------- */
import {
  buttonTitle,
  placeholder,
} from '../constants';

/* -----------------Component--------------- */
const ForgotSchema = object().shape({
  newPassword: string().required('Required'),
  reEnterPassword: string().required('Required'),
});
const Reset = () => {
  const {
    query: {
      code,
      username,
    },
  } = useRouter();

  const [errorMessage, setErrorMessage] = useState<unknown | undefined>(undefined);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangePassword = async () => {
    setIsSubmitting(true);
    try {
      if (typeof username === 'string' && typeof code === 'string') {
        // await forgotPasswordReset(username, code, values.newPassword);

        setIsSubmitting(false);
        Router.push('/login');
      }
    } catch (err) {
      setErrorMessage(err);
    }
    setIsSubmitting(false);
  };

  const clearError = () => {
    setErrorMessage(null);
  };

  const theme = useTheme();

  return (
    <>
      <Typography variant="h6" align="center">
        {I18n.get('Reset your password')}
      </Typography>
      {
          typeof errorMessage === 'string'
            ? (
              <Typography variant="subtitle2" color="error">
                {errorMessage}
              </Typography>
            ) : null
        }
      <Formik
        initialValues={{
          newPassword: '',
          reEnterPassword: '',
        }}
        validationSchema={ForgotSchema}
        onSubmit={handleChangePassword}
      >
        <Form>
          <Field
            type="password"
            name="newPassword"
            placeholder={I18n.get(placeholder.newPassword)}
            component={TextField}
            variant="outlined"
            margin="normal"
            fullWidth
            autoComplete="newPassword"
            onFocus={() => clearError()}
            disabled={isSubmitting}
          />
          <Field
            type="password"
            name="reEnterPassword"
            placeholder={I18n.get(placeholder.reenterPassword)}
            component={TextField}
            variant="outlined"
            margin="normal"
            fullWidth
            style={{ marginTop: 8 }}
            onFocus={() => clearError()}
            disabled={isSubmitting}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{
              margin: theme.spacing(1, 0, 1),
              borderRadius: 50,
              height: 48,
            }}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <CircularProgress
                color="secondary"
                size={24}
              />
            ) : buttonTitle.resetPassword}
          </Button>
        </Form>
      </Formik>
    </>
  );
};
export default Reset;
