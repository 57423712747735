/* eslint-disable @typescript-eslint/no-explicit-any */
/* ------------------- Globals ------------------- */
import React, { useEffect, useState } from 'react';

/* ----------------------- 3rd Party ------------------ */
import Router from 'next/router';
import {
  Formik,
  Form,
  Field,
} from 'formik';
import { TextField } from 'formik-mui';
import { I18n } from '@aws-amplify/core';
import { Button, CircularProgress, Typography } from '@mui/material';
import API from '@aws-amplify/api';

/* -----------------Configs--------------- */
import setUpAmplifyConfig from '../../../configs/amplify';

/* ------------------- Schema ------------------- */
import {
  signUpValidationSchema,
  signInValidationSchema,
  forgotPasswordValidationSchema,
} from '../schema';

/* -----------------------  Constants ------------------ */
import {
  buttonTitle,
  placeholder,
} from '../constants';

import { getErrorMessage, getInitialValues } from '../utils';

interface Params {
  success? : boolean,
  error? : boolean,
  username? : string
}
type Props = {
  variant: string,
  refreshAuth?: () => void,
  setEnableChangePassword?: (params : Params) => void,
  disableSocialLogin?: (v: boolean) => void,
};

const AuthInput = (props: Props) => {
  const {
    variant,
    refreshAuth,
    setEnableChangePassword,
    disableSocialLogin,
  } = props;
  const [schema, setSchema] = useState<any>(null);
  const [showError, setShowError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>(null);

  const clearError = () => setErrorMessage(null);

  const updateAWSConfig = () => {
    API.configure(setUpAmplifyConfig());
  };

  const handleSignInNavigation = () => {
    Router.replace('/dashboard');
  };
  useEffect(() => {
    if (variant === 'signup') {
      setSchema(signUpValidationSchema);
    } else if (variant === 'login') {
      setSchema(signInValidationSchema);
    } else {
      setSchema(forgotPasswordValidationSchema);
    }
  }, [variant]);

  const getActionName = () => {
    if (variant === 'signup') {
      return buttonTitle.signUp;
    } if (variant === 'login') {
      return buttonTitle.signIn;
    }
    return buttonTitle.forgotPassword;
  };

  const updateAuth = async () => {
    if (typeof refreshAuth === 'function') {
      await refreshAuth();
    }
  };

  const handleForgotPassword = async () => {
    clearError();
    setIsSubmitting(true);
    try {
      // await forgotPasswordVerification(values.username);
      setIsSubmitting(false);
      if (typeof setEnableChangePassword === 'function') {
      //  setEnableChangePassword({ success: true, username: values.username });
      }
    } catch (error) {
      const err = getErrorMessage(error as string);
      setErrorMessage(err);
      if (typeof setEnableChangePassword === 'function') {
        setEnableChangePassword({ error: true });
      }
    }
    setIsSubmitting(false);
  };
  const handleLoggedInNavigation = async () => {
    updateAWSConfig();
    await updateAuth();
    setIsSubmitting(false);
    handleSignInNavigation();
  };
  const handleSignIn = (async () => {
    setIsSubmitting(true);
    clearError();
    if (disableSocialLogin) {
      disableSocialLogin(true);
      try {
        // await signIn(values.username, values.password);
        disableSocialLogin(false);
        await handleLoggedInNavigation();
      } catch (err : any) {
        const message = decodeURIComponent(err.message);
        const parsedData = message.substring(
          message.lastIndexOf('[') + 1,
          message.lastIndexOf(']') - 1,
        );
        if (parsedData.length === 0) {
          const error = getErrorMessage(err);
          setErrorMessage(error);
        } else {
          const {
            error,
          } = JSON.parse(parsedData);
          const errMsg = getErrorMessage(error);
          setErrorMessage(errMsg);
        }
        setIsSubmitting(false);
      }
      disableSocialLogin(false);
    }
  });

  const handleSignup = (async () => {
    setIsSubmitting(true);
    clearError();
    if (disableSocialLogin) {
      disableSocialLogin(true);
      try {
      //  await signUp(values.username, values.password, values.email);
        await handleLoggedInNavigation();
      } catch (err : any) {
        const stringified = JSON.stringify(err);
        const {
          code,
        } = JSON.parse(stringified);
        const errMsg = getErrorMessage(code);
        setErrorMessage(errMsg);
        setIsSubmitting(false);
      }
      disableSocialLogin(false);
    }
  });

  const getOnSubmitAction = () => {
    setShowError(true);
    if (variant === 'signup') {
      return handleSignup();
    } if (variant === 'login') {
      return handleSignIn();
    }
    return handleForgotPassword();
  };

  return (
    <>
      {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        errorMessage ? (
          <Typography variant="body2" color="error">
            {errorMessage}
          </Typography>
        ) : null
      }
      <>
        <Formik
          initialValues={getInitialValues(variant)}
          validationSchema={schema}
          validateOnBlur={showError}
          validateOnChange={showError}
          onSubmit={getOnSubmitAction}
        >
          <Form>
            <Field
              type="username"
              name="username"
              placeholder={I18n.get(variant === 'forgot' ? placeholder.usernameOrEmail : placeholder.username)}
              component={(p : any) => <TextField {...p} />}
              variant="outlined"
              margin="dense"
              fullWidth
              autoFocus
              disabled={isSubmitting}
            />
            {
                variant === 'signup' ? (
                  <Field
                    type="email"
                    name="email"
                    placeholder={I18n.get(placeholder.email)}
                    component={TextField}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    disabled={isSubmitting}
                  />
                ) : null
              }
            {
                variant !== 'forgot' ? (
                  <Field
                    type="password"
                    name="password"
                    placeholder={I18n.get(placeholder.password)}
                    component={TextField}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    disabled={isSubmitting}
                  />
                ) : null
              }
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{
                margin: (theme) => theme.spacing(2, 0, 2),
                borderRadius: 50,
                height: 48,
              }}
              disabled={isSubmitting}
            >
              {
                  isSubmitting ? (
                    <CircularProgress
                      color="secondary"
                      size={24}
                    />
                  ) : I18n.get(getActionName())
                }
            </Button>
          </Form>

        </Formik>

      </>
    </>
  );
};

export default AuthInput;
