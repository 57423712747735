import {
  createContext,
  useState,
  Dispatch,
  SetStateAction,
  useContext,
} from 'react';
import {
  PageTypes,
} from '../types/common';

interface ContextValueType {
  pageType: PageTypes,
  setPageType? : Dispatch<SetStateAction<PageTypes>>
}
const initialValue = {
  pageType: PageTypes.dashboard,
  setPageType: undefined,
};

const Context = createContext<ContextValueType>(initialValue);
const PageProvider = ({
  children,
} : {
    children : React.ReactElement
}) => {
  const [pageType, setPageType] = useState<PageTypes>(PageTypes.dashboard);

  const value = {
    pageType,
    setPageType,
  };
  return (
    <Context.Provider
      value={value}
    >
      {children}
    </Context.Provider>
  );
};

const usePageContext = () => {
  const pageContext = useContext(Context);
  return pageContext;
};

export {
  usePageContext,
  PageProvider,
  PageTypes,
};
