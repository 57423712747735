import {
  useEffect,
  useCallback,
} from 'react';
import {
  useRouter,
} from 'next/router';
/* -----------------Analytics--------------- */
import {
  trackPageView,
  trackEvent,
} from '../analytics/tracking';

const useAnalytics = (params? : Record<string, string>, type? : 'pageview' | 'event',
  runOnMount = true) => {
  const isPageView = type === 'pageview';

  const {
    isReady,
    asPath,
  } = useRouter();
  useEffect(() => {
    if (isReady && runOnMount && params) {
      if (isPageView) {
        trackPageView({
          ...params,
          url: asPath,
        });
      } else {
        const {
          eventName,
          ...rest
        } = params;
        trackEvent(eventName, rest);
      }
    }
  }, [asPath, isPageView, isReady, params, runOnMount]);

  const trackCustomEvent = useCallback((
    eventParams : Record<string, string>,
    userProperty? : string,
  ) => {
    const {
      eventName,
      ...rest
    } = eventParams;
    trackEvent(eventName, rest, userProperty);
  }, []);

  return {
    trackCustomEvent,
  };
};

export default useAnalytics;
