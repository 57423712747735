const error = {
  contactSupport: 'We have found issues with your account. Please contact memorang support',
  unknown: 'Something went wrong! Please try again later',
  invalid: 'The username or password you entered is wrong.',
  network: 'Unable to connect. Please check your internet connection',
  enterCode: 'Please enter your code',
  email: {
    required: 'Email is required',
    invalid: 'Please enter a valid email address.',
  },
  therapyExamDate: {
    required: 'Date is required',
  },
  therapyGraduationYear: {
    required: 'Graduation year is required',
  },
  therapySchoolname: {
    required: 'School name is required',
  },
  therapyUsername: {
    required: 'Username is required',
    minLength: 'Username should be longer than 3 characters',
    maxLength: 'Username should be shorter than 30 characters',
  },
  username: {
    taken: 'Username is already taken. Please try a different one',
    minLength: 'Username should be longer than 3 characters',
    maxLength: 'Username should be shorter than 30 characters',
    invalid: 'Please enter a username that is at least 3 characters and contains only letters or numbers.',
    invalidChar: 'Only alphabets, numbers, - and _ are allowed',
  },
  emailOrUsername: {
    enter: 'Please enter a username or email.',
    required: 'Email or Username is required',
    taken: 'This email or username already exists',
    doesNotExist: 'We can’t find that username or email. Please try again or sign up with a new account.',
  },
  password: {
    enter: 'Please enter a password.',
    current: 'Current password is required',
    new: 'New password is required',
    confirm: 'Please confirm your password',
    minLength: 'Password should be longer than 7 characters',
    maxLength: 'Password should be shorter than 50 characters',
    invalid: 'Please choose a password with at least 8 characters.',
    invalidChar: "Only these !@#$%^&*()_-'  special characters are allowed",
  },
};
const authExpression = {
  username: /^[a-zA-Z0-9_.-]*$/,
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  password: /^[a-zA-Z0-9!@#$%^&*()_-]*$/,
};

const screenTitle = {
  signIn: 'SignIn',
  signUp: 'SignUp',
  forgot: 'Forgot',
  landing: 'Landing',
  forgotPassword: 'Forgot Password',
  welcome: 'Welcome Back!',
  verifyAccount: 'Verify Account',
  newAccount: 'Create New Account',
  welcomeTherapy: 'Welcome to TherapyEd!',
};

const buttonTitle = {
  signIn: 'Login',
  signUp: 'Sign up',
  forgotPassword: 'Reset password',
  resetPassword: 'Set new Password',
  contactSupport: 'Contact Support',
  cancel: 'Cancel',
};

const prompt = {
  rememberPassword: 'Remember your password?',
  newAccount: 'Don\'t have an account?',
  existingAccount: 'Already have an account?',
  havingTrouble: 'Still having trouble?',
  checkEmail: 'We sent a confirmation code to your new email address. Please copy and enter it above.',
};
const username = 'Username';
const email = 'Email';
const usernameOrEmail = 'Username or email';
const password = 'Password';
const code = 'Code';
const newPassword = 'New Password';
const reenterPassword = 'Re-enter new password';
const confirmationCode = 'Enter confirmation code';
const therapyUsername = 'Name';
const therapyEmail = 'Email';
const therpaySchool = 'School';
const therapyGraduation = 'Graduation Year';
const therapyExamDate = 'Date';
const placeholder = {
  username,
  email,
  usernameOrEmail,
  password,
  code,
  newPassword,
  reenterPassword,
  confirmationCode,
  therapyUsername,
  therpaySchool,
  therapyGraduation,
  therapyExamDate,
  therapyEmail,
};

const keyboard = {
  done: 'Done',
  next: 'Next',
};

const socialAuth = {
  login: 'LOG IN WITH SOCIAL ACCOUNT',
  facebook: 'Facebook',
  google: 'Google',
  apple: 'SignInWithApple',
};

const otp = {
  title: "We've sent you an email with a verification code, Please enter it below:",
  label: 'Enter code',
  placeholder: 'Enter code',
  invalidOtp: 'Invalid verification code provided, Please try again',
  length: 6,
};

export {
  error,
  authExpression,
  screenTitle,
  buttonTitle,
  prompt,
  placeholder,
  keyboard,
  socialAuth,
  otp,
};
