import {
  Tenant,
} from '../../../types/tenant';

const initialSessionState = {
  totalQuestions: 0,
  id: '',
  items: [],
  loading: false,
  launchSession: false,
  error: false,
  totalTime: 8400,
  timeElapsed: 0,
  states: [],
  status: undefined,
  review: false,
  numericId: undefined,
  lastAnsweredItemIndex: 0,
  sessionTime: 0,
};

const initialSessionTimeState = {
  timeElapsed: 0,
};

const learningProgressTypeLabel = {
  [Tenant.mh]: {
    none: 'Not Answered',
    low: 'Incorrect',
    high: 'Correct',
  },
};

// table
const headings = ['Order', 'QID', 'Type', 'Time Spent', 'Correct'];

// Time in sec to auto navigate to report page once time exceeds
const autoNavigateTimeout = 3000;
export {
  initialSessionState,
  initialSessionTimeState,
  learningProgressTypeLabel,
  headings,
  autoNavigateTimeout,
};
