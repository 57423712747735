// @flow

/* ---------------------- Constants ----------------- */
import {
  placeholder,
  keyboard,
} from '../constants';

export const signUpFormData = [
  {
    key: 1,
    name: 'username',
    label: placeholder.username,
    placeHolder: placeholder.username,
    returnKeyType: 'next',
    autoFocus: true,
    returnKeyLabel: keyboard.next,
    secureTextEntry: false,
  },
  {
    key: 2,
    name: 'email',
    label: placeholder.email,
    placeHolder: placeholder.email,
    returnKeyType: 'next',
    returnKeyLabel: keyboard.next,
    secureTextEntry: false,
    autoFocus: false,
  },
  {
    key: 3,
    name: 'password',
    label: placeholder.password,
    placeHolder: placeholder.password,
    returnKeyType: 'done',
    returnKeyLabel: keyboard.done,
    secureTextEntry: true,
    autoFocus: false,
  },
];
export const signInFormData = [
  {
    key: 1,
    name: 'username',
    label: placeholder.usernameOrEmail,
    placeHolder: placeholder.usernameOrEmail,
    returnKeyType: 'next',
    autoFocus: true,
    returnKeyLabel: keyboard.next,
    secureTextEntry: false,
  },
  {
    key: 2,
    name: 'password',
    label: placeholder.password,
    placeHolder: placeholder.password,
    returnKeyType: 'done',
    returnKeyLabel: keyboard.done,
    secureTextEntry: true,
    autoFocus: false,
  },
];

export const forgotFormData = [
  {
    key: 1,
    name: 'username',
    label: placeholder.usernameOrEmail,
    placeHolder: placeholder.usernameOrEmail,
    returnKeyType: 'done',
    returnKeyLabel: keyboard.done,
    secureTextEntry: false,
    autoFocus: true,
  },
];

export const resetFormData = [
  {
    key: 1,
    name: 'code',
    label: placeholder.code,
    placeHolder: placeholder.code,
    returnKeyType: 'next',
    autoFocus: true,
    returnKeyLabel: keyboard.next,
    secureTextEntry: false,
  },
  {
    key: 2,
    name: 'password',
    label: placeholder.password,
    placeHolder: placeholder.password,
    returnKeyType: 'done',
    returnKeyLabel: keyboard.done,
    secureTextEntry: true,
    autoFocus: false,
  },
];
