/* eslint-disable @typescript-eslint/no-explicit-any */
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { HYDRATE, createWrapper } from 'next-redux-wrapper';
import thunkMiddleware from 'redux-thunk';
import { sessionReducer, sessionTimeReducer } from '../features/session/reducer';

const bindMiddleware = (middleware : any) => applyMiddleware(...middleware);

const combinedReducer = combineReducers({
  sessionReducer,
  sessionTimeReducer,
});

const reducer = (state : any, action : any) => {
  if (action.type === HYDRATE) {
    return state;
  }
  return combinedReducer(state, action);
};

const initStore = () => createStore(reducer, bindMiddleware([thunkMiddleware]));

const store = initStore();

export type AppDispatch = typeof store.dispatch
// eslint-disable-next-line import/prefer-default-export
export const wrapper = createWrapper(initStore);
