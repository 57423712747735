/* -----------------Globals--------------- */
import React, {
  useEffect,
} from 'react';
import Router, {
  useRouter,
} from 'next/router';

/* -----------------UI--------------- */
import { Box } from '@mui/material';

/* -----------------Context--------------- */
import {
  useAuthContext,
} from '../../context/AuthContext';

/* -----------------Types--------------- */
import {
  BreadcrumbProp,
} from '@components/CustomBreadcrumb';

/* -----------------Child components--------------- */
import Header from '@components/chrome/Header';
import HeaderBreadcrumb from '@components/chrome/HeaderBreadcrumb';
import Loader from '@components/Loader';

interface Props {
  breadcrumbs?: BreadcrumbProp[],
  children: React.ReactElement,
}

const PageContainer = (props: Props) => {
  const {
    isReady,
    pathname,
  } = useRouter();
  const { breadcrumbs, children } = props;
  const { isAuthenticated, loading } = useAuthContext();
  const showBreadcrumbs = isAuthenticated && breadcrumbs && breadcrumbs.length > 0;

  useEffect(() => {
    if (isReady && !loading) {
      if (!isAuthenticated && pathname !== '/') {
        Router.push('/');
      }
    }
  }, [isAuthenticated, isReady, pathname, loading]);

  return (
    <Box minHeight="calc(100vh - 118px)">
      <Header>
        {showBreadcrumbs ? <HeaderBreadcrumb breadcrumbs={breadcrumbs} /> : null}
      </Header>
      {loading ? <Loader /> : children}
    </Box>
  );
};

export default PageContainer;
