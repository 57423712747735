import {
  PageTypes,
} from '../types/common';
import {
  TrackingEvents,
} from './types';

import mixpanel from 'mixpanel-browser';

const getDistinctId = async () => {
  const distinctId = mixpanel.get_distinct_id();
  return distinctId;
};

const initMixpanel = async () => {
  mixpanel.init(process.env.MIXPANEL_SECRET_KEY || 'fa9c619bbdd5631c565a957a1bc0b1b5');
};

/* ======== AUTHENTICATION ========= */
const trackLogin = async (uid: string) => {
  // Mixpanel
  mixpanel.track('login');
  mixpanel.identify(uid);
};

  type Params = {
    userId :string,
    email : string,
    username : string
  }

const trackPageView = async (params: Record<string, string>) => {
  // Mixpanel
  initMixpanel();
  mixpanel.register_once({ 'First time': true }); // Tracks first-time visitors
  mixpanel.track('Page_Viewed', { ...params }, () => {
    setTimeout(() => {
      mixpanel.register({
        'First time': false,
      });
    }, 500);
  });
  mixpanel.people.increment({
    pages_viewed: 1,
  });
};
const registerUserProperties = async (params: Params) => {
  const { userId, email, username } = params;
  mixpanel.register({
    userId,
    uuid: userId,
    email,
    username,
  });
  mixpanel.people.set({
    uuid: userId,
    email,
    username,
  });
};

const trackEvent = async (
  eventName : string,
  params : Record<string, string>,
  userProperty? : string,
) => {
  mixpanel.track(eventName, {
    ...params,
  });
  if (userProperty) {
    mixpanel.people.increment({
      [userProperty]: 1,
    });
  }
};

const trackLogout = async () => {
  mixpanel.track('logout');
  mixpanel.reset();
};
export {
  getDistinctId,
  initMixpanel,
  trackLogin,
  registerUserProperties,
  trackPageView,
  trackEvent,
  PageTypes,
  TrackingEvents,
  trackLogout,
};
