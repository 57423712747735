enum TrackingEvents {
    ItemViewed = 'Item_Viewed',
    ItemReviewed = 'Item_Reviewed',
    DialogOpened = 'Dialog_Opened',
    startSession = 'Start_Session_Clicked',
    listItemClicked = 'List_Item_Clicked',
    previousTestChartClicked = 'Previous_Test_Chart_Clicked',
    studySessionPauseClicked = 'Study_Session_Pause_Clicked',
    sessionPaused = 'Study_Session_Paused',
    sessionResumed = 'Study_Session_Resumed',
    sessionEnded = 'Study_Session_Ended',
    sessionReportDoneClicked = 'Session_Report_Done_Clicked',
    reviewAnswersClicked = 'Review_Answers_Clicked',
    logout = 'Logout'
}

enum DialogTrackingEventType {
  sessionConfig = 'Custom_Session_Config',
  reviewAnswers = 'Review_Answers',
  pauseStudySession = 'Pause_Study_Session',
  notEnoughItems = 'Not_Enough_Items'
}
enum ListItemClickedEventType {
  card = 'Card',
  list = 'List'
}
export {
  DialogTrackingEventType,
  TrackingEvents,
  ListItemClickedEventType,
};
