/* eslint-disable @next/next/no-img-element */
/* -----------------Globals--------------- */
import React from 'react';

/* -----------------MUI--------------- */
import {
  Grid,
  Stack,
} from '@mui/material';
// import Link from '@components/Link';

const PoweredBy = () => (
  <Grid
    item
  >
    <img
      width={220}
      height={80}
      src="/memorang_poweredby_dark.png"
      alt="poweredby logo"
    />
    <Stack
      direction="row"
    >
      {/* {memorangLinks.map(({
        name,
        href,
      }, index) => (
        <Link
          href={href}
          key={name}
          sx={{
            textDecoration: 'none',
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
          >
            <Typography
              variant="body2"
              color="secondary.main"
            >
              {name}
            </Typography>
            {index !== memorangLinks.length - 1 ? (
              <Typography
                variant="body2"
                color="text.primary"
                sx={{
                  px: 1,
                }}
              >
                {'\u2022'}
              </Typography>
            ) : null}
          </Stack>
        </Link>
      ))} */}
    </Stack>
  </Grid>
);

export default PoweredBy;
