/* -----------------Globals--------------- */
import React from 'react';

/* -----------------UI--------------- */
import {
  Stack, Button,
} from '@mui/material';

/* -----------------Icons--------------- */
import LogoutIcon from '@mui/icons-material/Logout';

/* -----------------Constants--------------- */
import links from '@utils/constants';

/* -----------------Analytics--------------- */
import {
  TrackingEvents,
} from '../../analytics/types';
import useAnalytics from '../../hooks/useAnalytics';

/* -----------------Utils--------------- */
import {
  store,
  Keys,
} from '../../utils/auth';

/* -----------------Child components--------------- */
import CustomBreadcrumb, {
  BreadcrumbProp,
} from '@components/CustomBreadcrumb';

interface Props {
  breadcrumbs : BreadcrumbProp[]
}
const HeaderBreadcrumb = (props: Props) => {
  const { breadcrumbs } = props;

  const { origin } = links;

  const {
    trackCustomEvent,
  } = useAnalytics();
  const logout = () => {
    store(Keys.userId, '');
    store(Keys.userName, '');
    store(Keys.userFullName, '');
    trackCustomEvent({
      eventName: TrackingEvents.logout,
    });
  };
  return (
    <Stack direction="row" justifyContent="space-between">
      <CustomBreadcrumb
        breadcrumbs={breadcrumbs}
      />
      <Button
        href={origin}
        variant="outlined"
        color="secondary"
        onClick={logout}
      >
        <LogoutIcon
          sx={{ mr: 1 }}
        />
        Exit
      </Button>
    </Stack>
  );
};

export default HeaderBreadcrumb;
